import React from 'react';
import { Link } from 'gatsby';

import { MetaData } from '../components';

const NotFound = () => {
	return (
		<div className="w-container w-container--padded not-found">
			<MetaData title="Page not found" />

			<div className="w-row">
				<div className="w-col">
					<h1 className="text-pink">Sorry, this page can't be found.</h1>
					<p className="lead text-align-center">
						Head back to the <Link to="/">home page</Link> or choose a link in
						the navigation above.
					</p>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
